<template>
  <div>
    <b-container fluid>
      <base-logo-wave class="base-logo"/>
      <h4>{{ $t('auth.thank_you_page.wavetraders') }}</h4>
    </b-container>
    <b-container class="text-center center-box">
      <img src="/img/check_icon.svg">
      <h3>{{ $t('auth.thank_you_page.thank_you') }}</h3>
      <p>
        {{ $t('auth.thank_you_page.please_check_your_email') }}
        <span>{{ email || email_query }}</span>
        {{ $t('auth.thank_you_page.to_confirm_the_address') }}</p>
      <router-link :to="{ name: 'login' }">{{ $t('auth.thank_you_page.go_to_login') }}</router-link>
    </b-container >
  </div>
</template>

<script>
import BaseLogoWave from '../../components/BaseLogoWave.vue'

export default {
  props: ['email'],
  data () {
    return {
      email_query: this.$route.query.email
    }
  },
  components: {
    BaseLogoWave
  }
}
</script>

<style scoped lang="scss">
.container-fluid {
  @media (max-width: 767.98px) {
    text-align: center;
  }
}

.base-logo {
  display: inline-block;
  vertical-align: text-bottom;
}

h4 {
  display: inline-block;
  line-height: 60px;
  font-size: 20px;
  font-weight: 300;
  margin-left: 15px;
}

.center-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767.98px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 100px;
  }

  @media (max-height: 600px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 100px;
  }
}

img {
  width: 160px;
  margin-bottom: 45px;
}

h3 {
  font-size: 26px;
  color: var(--charcoal-grey-white);
  font-weight: 400;
  margin-bottom: 50px;
}

p {
  font-family: 'Athelas', arial, sans-serif;
  font-size: 15px;
  max-width: 320px;
  margin: 0 auto;
  color: var(--black-white);
  line-height: 1.5;
  margin-bottom: 60px;
}

span {
  color: var(--blueberry);
}

a {
  display: block;
  color: var(--white);
  background-color: var(--blueberry);
  padding: 22px 25px;
  font-size: 14px;
  max-width: 390px;
  margin: 0 auto;

  &:hover {
    text-decoration: none;
  }
}

</style>
